import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import logoFH from "./assets/logoFH.svg";
import style from "./styleImpressum.module.css";

export const Impressum = () => {
  return (
    <Box sx={{ width: "100%", maxWidth: 600 }}>
      <div className={style.textComponent}>
        <Stack direction="row" spacing={{ xs: 10, sm: 20, md: 20 }}>
          <Typography variant="h6" fontWeight={600} sx={{ mt: 2, mb: 4 }}>
            Impressum
          </Typography>
          <img src={logoFH} alt="Logo FHSalzburg" width={50} />
        </Stack>
        <Stack direction="column">
          <Typography variant="h6" sx={{ mt: 6, mb: 2 }}>
            👋 Hi! :)
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Skinavie is the MultiMediaProject 2b by Lisa-Marie Rader, Elizaveta
            Zaburunova, and Benjamin Fernerberger (Team Campus Lehen) from the
            University of Applied Sciences Salzburg.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
            Quellen:
          </Typography>
          <Stack direction="row" spacing={{ xs: 1, sm: 1.5, md: 2 }}>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Images:
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              https://unsplash.com/
            </Typography>
          </Stack>
          <Stack direction="row" spacing={{ xs: 1, sm: 3, md: 4 }}>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Icons:
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              https://mui.com/material-ui/material-icons/
            </Typography>
          </Stack>
          <Stack direction="row" spacing={{ xs: 2, sm: 4, md: 5 }}>
            <Typography variant="body1">Map:</Typography>
            <Stack direction="column" spacing={{ xs: 1, sm: 2, md: 2 }}>
              <Typography variant="body1">
                https://www.openstreetmap.org/
              </Typography>
              <Typography variant="body1" sx={{ mb: 4 }}>
                https://openskimap.org/
              </Typography>
              <Typography variant="body1" sx={{ mb: 4 }}>
                https://www.mapbox.com/
              </Typography>
              <Typography variant="body1" sx={{ mb: 4 }}>
                https://openmaptiles.org/
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </div>
    </Box>
  );
};
