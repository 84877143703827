import * as React from "react";
import { CardDefault } from "./Card";
import { Typo } from "./Typographie";
import { useEffect, useState } from "react";
import style from "./ExploreListPage.module.css";
import { red } from "@mui/material/colors";
import Typography from "@mui/material/Typography";

export const ExploreList = () => {
  const [areas, setAreas] = useState([]);
  const url = `${process.env.REACT_APP_URL}/api/v1/areas`;

  useEffect(() => {
    fetch(url)
      .then((resp) => resp.json())
      .then((data) => setAreas(data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div className={style.content}>
      <Typo type={"h4"}>Explore skiing areas</Typo>
      {areas.length == 0 || areas == null ? (
        <Typography
          variant="overline"
          component="div"
          fontWeight={600}
          fontSize={16}
          mt={5}
        >
          Loading ...
        </Typography>
      ) : (
        areas.map((area) => {
          return <CardDefault key={area.id} area={area} />;
        })
      )}
    </div>
  );
};
