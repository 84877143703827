import * as React from "react";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import imgSki from "./assets/img_ski.jpg";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";

export const CardDefault = ({ area }) => {
  const navigate = useNavigate();
  const description = ShortenDescription(area) + "...";
  return (
    <>
      <List
        key={area.id}
        sx={{ display: "flex" }}
        onClick={() => navigate(`area/${area.id}`)}
      >
        <CardMedia
          component="img"
          sx={{ width: 151 }}
          image={imgSki}
          alt="Live from space album cover"
        />
        <CardActionArea>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <CardContent sx={{ flex: "1 0 auto" }}>
                {area.country !== null && <Typography
                variant="overline"
                color="text.secondary"
                component="div"
              >
                {area.country}
              </Typography>}
                {area.name !== null && <Typography component="div" variant="h5">
                {area.name}
              </Typography>}
                {description !== null && <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                mt={2}
              >
                {description}
              </Typography>}
            </CardContent>
          </Box>
        </CardActionArea>
      </List>
      <Divider variant="inset" />
    </>
  );
};

function ShortenDescription(area) {
  var desc = ""
  desc += "The ski area "+ area.name+ " is located in "+(area.country? area.country : "") +". "
  desc += ""

  return desc.substring(0, 70);
}
