import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { grey, indigo, red } from "@mui/material/colors";
import { daten } from "./data";
import style from "./Accordion.module.css";

export const NavList = ({ data }) => {
  let slopeData = daten.map((item) => {
    return {
      id: item[0],
      value1: item[1],
      value2: item[2],
      name: item[3],
      slopeLength: item[4],
    };
  });

  return (
    <>
      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="subtitle1">Route</Typography>
        </AccordionSummary>
        {slopeData.map((slope, index) => {
          return (
            <AccordionDetails key={slope.id}>
              <Typography variant="subtitle2" fontWeight={600}>
                {index + 1 }. Step:
              </Typography>
              <div className={style.infobox}>
                <Typography variant="body2">
                  {slope.name}
                </Typography>
              </div>
            </AccordionDetails>
          );
        })}
      </Accordion>
    </>
  );
};
