import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import img from "./assets/img_ski.jpg"

export const ImageBar = ({ name, place }) => {
    return (
        <ImageList
            cols={1}
        >
            <ImageListItem>
                <img
                    src={img}
                    alt={name}
                    loading="lazy"
                />
                <ImageListItemBar
                    title={name}
                />
            </ImageListItem>
        </ImageList>
    );
}
