import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import NavigationIcon from '@mui/icons-material/Navigation';
import ExploreIcon from '@mui/icons-material/Explore';
import MapIcon from '@mui/icons-material/Map';
import DirectionsIcon from '@mui/icons-material/Directions';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import Stack from '@mui/material/Stack';
import styles from './HelpModal.module.css';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
};

export const HelpModal = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
        <div className={styles.iconStyle}>
            <IconButton aria-label="Example" onClick={handleOpen}>
                <InfoIcon color="action" />
            </IconButton>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            Information about Skinavie
                        </Typography>
                        <Typography id="transition-modal-description" gutterbottom={"true"} sx={{mt: 2, mb: 4}}>
                            Skinavie is going to help you explore different skiing areas or get around the area you are
                            currently at. You want to go to a certain restaurant but don't know where to go? Select a flag
                            on the map and let yourself navigate to the destination.
                        </Typography>
                        <Stack spacing={2}>
                            <div className={styles.rowLayout}>
                                <NavigationIcon color="action" sx={{paddingRight: 5}}/>
                                <Typography variant="body2" color="text.secondary" width={'80%'}>
                                    Open the navigation to a certain destination
                                </Typography>
                            </div>
                            <div className={styles.rowLayout}>
                                <ExploreIcon color="action" sx={{paddingRight: 5}}/>
                                <Typography variant="body2" color="text.secondary" width={'80%'}>
                                    Explore all of the possible skiing areas and find information about slopes, restaurants, ...
                                </Typography>
                            </div>
                            <div className={styles.rowLayout}>
                                <MapIcon color="action" sx={{paddingRight: 5}}/>
                                <Typography variant="body2" color="text.secondary" width={'80%'}>
                                    Explore the skiing area you are on a map
                                </Typography>
                            </div>
                        </Stack>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};
