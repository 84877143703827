import * as React from 'react';
import Link from '@mui/material/Link';
import {useNavigate} from "react-router-dom";

export const ImpressumLink = () => {
    const navigate = useNavigate();
    return (
        <Link
            component="button"
            variant="body2"
            sx={{ backgroundColor: "white", width: '100%' }}
            color="text.primary"
            onClick={() =>
                navigate(`/impressum`)
            }
        >
            Impressum
        </Link>
    );
}


