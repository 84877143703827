import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Typo = ({type, children}) => {
    return (
        <Box sx={{ width: '100%', maxWidth: 600  }}>
            {type === "h1" && <Typography variant="h1" component="div" gutterBottom>
                {children}
            </Typography>}

            {type === "h2" && <Typography variant="h2" gutterBottom component="div">
                {children}
            </Typography>
            }

            {type === "h3" && <Typography variant="h3" gutterBottom component="div">
                {children}
            </Typography>
            }

            {type === "h4" && <Typography variant="h4" gutterBottom component="div">
                {children}
            </Typography>
            }

            {type === "h5" && <Typography variant="h5" gutterBottom component="div">
                {children}
            </Typography>
            }

            {type === "h6" &&<Typography variant="h6" gutterBottom component="div">
                {children}
            </Typography>
            }
            {type === "subtitle1" && <Typography variant="subtitle1" gutterBottom component="div">
                {children}
            </Typography>
            }

            {type === "subtitle2" && <Typography variant="subtitle2" gutterBottom component="div">
                {children}
            </Typography>}

            {type === "body1" && <Typography variant="body1" gutterBottom>
                {children}
            </Typography>}

            {type === "body2" &&<Typography variant="body2" gutterBottom>
                {children}
            </Typography>}

            {type === "button" &&<Typography variant="button" display="block" gutterBottom>
                {children}
            </Typography>}

            {type === "caption" &&<Typography variant="caption" display="block" gutterBottom>
                {children}
            </Typography>}

            {type === "overline" &&<Typography variant="overline" display="block" gutterBottom>
                {children}
            </Typography>}
        </Box>
    );
}
