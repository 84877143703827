import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { lightGreen, amber, deepPurple, grey, red } from '@mui/material/colors';

export const ShortInfo = ({description, children}) => {
    return (
        <Box sx={{ width: '30%', maxWidth: 600 }}>
            <Typography variant="overline" component="div" >
                {description}
            </Typography>
            <Typography variant="subtitle1" component="div">
                {children}
            </Typography>
        </Box>
    );
};
