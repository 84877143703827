import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import MapIcon from '@mui/icons-material/Map';
import ExploreIcon from '@mui/icons-material/Explore';
import {useNavigate} from 'react-router-dom';
import {ImpressumLink} from "./ImpressumLink";
import style from "./Navigation.module.css";

export const Navigation = () => {
    return (
        <div className={style.navbar}>
            <ImpressumLink />
        </div>
    );
};
