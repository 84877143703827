import * as React from "react";
import ReactMapGL, { Source, Layer, Marker, Popup } from "react-map-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Icons } from "./Icon";
import style from "./MapPage.module.css";
import { ConnectAllSpots } from "./GPS";

/* eslint import/no-webpack-loader-syntax: off */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",

  backgroundColor: grey[100],
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 60,
  height: 6,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: "absolute",
  top: 70,
  left: "calc(50% - 25px)",
}));

function SimpleMediaQuery() {
  const matches = useMediaQuery("(min-width:800px)"); //true: desktop, false: mobile
  return matches ? "true" : "false";
}

//mapboxgl.accessToken =
//  "pk.eyJ1IjoibGl6emFidXJ1bm92YSIsImEiOiJjbDJxZzMyNWgxcWJ0M2JtdXZmM2d3MjNkIn0.JNfgF_LqWskUmYVTVmBKWw";

export const ReactMapWithSwiper = ({ geodat } = "") => {
  // mapbox://lizzaburunova.3j3xscg7
  //const [showPopup, setShowPopup] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [area, setArea] = useState({});
  const url = `${process.env.REACT_APP_URL}/api/v1/areas/${params.Id}`;
  //TODO
  const lat = 47.376107;
  const long = 13.6539977;

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    fetch(url)
      .then((resp) => resp.json())
      .then((data) => setArea(data))
      .catch((error) => console.log(error));
  }, []);

  const allSpots = ConnectAllSpots(area.restaurants, area.lifts, area.slopes);

  const dataLayer = {
    id: "line",
    type: "line",
    paint: {
      "line-color": "#74ee15",
      "line-width": 5,
    },
  };

  return (
    <>
      <ReactMapGL
        mapboxAccessToken="pk.eyJ1IjoibGl6emFidXJ1bm92YSIsImEiOiJja3oyczZsNGgwMHgxMnZtOHFqajdnZ3MyIn0.TdPj6J3ueE_evqR7tbM9FQ"
        initialViewState={{
          longitude: long,
          latitude: lat,
          zoom: 14,
        }}
        style={{ height: 600, marginTop: 50 }}
        mapStyle="https://api.maptiler.com/maps/winter/style.json?key=SNTFwlDVCrzkTscvYOcn"
      >
        <Root>
          {SimpleMediaQuery() === "true" && (
            <Global
              styles={{
                ".MuiDrawer-root > .MuiPaper-root": {
                  overflow: "visible",

                  margin: "50px 30% 0px 30%",
                },
              }}
            />
          )}

              <Source type="geojson" data={geodat}>
                <Layer {...dataLayer} />
              </Source>

          <Marker
            longitude={long}
            latitude={lat}
            anchor="bottom"
            onClick={(e) => {
              e.originalEvent.stopPropagation();
              setOpen(true);
            }}
          >
            <IconButton aria-label="interestPoint">
              <FmdGoodIcon color="primary" />
            </IconButton>
          </Marker>

          {open && (
            <SwipeableDrawer
              width={"40%"}
              anchor="bottom"
              open={open}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              swipeAreaWidth={drawerBleeding}
              disableSwipeToOpen={false}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <StyledBox
                sx={{
                  position: "absolute",
                  top: -drawerBleeding,
                  borderTopLeftRadius: 8,
                  borderTopRightRadius: 8,
                  visibility: "visible",
                  right: 0,
                  left: 0,
                }}
              >
                <Puller />
              </StyledBox>
              <StyledBox
                sx={{
                  px: 2,
                  pb: 2,
                  height: "100%",
                  overflow: "auto",
                }}
              >
                <Box sx={{ mt: 2, ml: 2 }}>
                  <div className={style.navIcon}>
                    <Typography variant="overline" component="div">
                      Find your way
                    </Typography>
                    <Icons
                      iconName={"NavigationIcon"}
                      onClick={() =>
                        navigate(
                          `/area/${area.id}/map/${area.id}/navigate/${area.id}`
                        )
                      }
                    />
                  </div>

                      <>
                        <Typography variant="overline" component="div">
                          {allSpots[0].name}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          fontWeight={600}
                          fontSize={20}
                        ></Typography>
                        <Typography
                          variant="body2"
                          gutterBottom
                          color="text.secondary"
                          sx={{ mt: 2 }}
                        >
                          Lifttype: {allSpots[0].lift_type}
                        </Typography>
                        <Typography
                            variant="body2"
                            gutterBottom
                            color="text.secondary"
                            sx={{ mt: 2 }}
                        >
                          Seats: {allSpots[0].seats}
                        </Typography>
                      </>

                </Box>
              </StyledBox>
            </SwipeableDrawer>
          )}
        </Root>
      </ReactMapGL>
    </>
  );
};
