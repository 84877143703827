import * as React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ImageBar } from "./ImageBar";
import { Typo } from "./Typographie";
import style from "./Area.module.css";
import { ShortInfo } from "./ShortInfo";
import {
  AccordionDisabled,
  AccordionLifts,
  AccordionRestaurants,
  AccordionSlopes,
} from "./Accordion";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import { Icons } from "./Icon";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { ReactMap } from "./Map";
import "mapbox-gl/dist/mapbox-gl.css";

export const Area = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [area, setArea] = useState({});
  const url = `${process.env.REACT_APP_URL}/api/v1/areas/${params.Id}`;

  useEffect(() => {
    fetch(url)
      .then((resp) => resp.json())
      .then((data) => setArea(data))
      .catch((error) => console.log(error));
  }, []);

  const slopeLength = area.slope_length;

  return (
    <>
      <ImageBar name={area.name} place={area.info} />

      <div className={style.textcontent}>
        <div className={style.navigateIcon}>
          <Typography variant="overline" component="div">
            Click to navigate in area
          </Typography>
          <Icons
            iconName={"NavigationIcon"}
            onClick={() => navigate(`/area/${area.id}/map/${area.id}/navigate/${area.id}`)}
          />
        </div>
          <Typo type={"body2"} width={"100%"}>
            {ShortenDescription(area)}
          </Typo>
        <div className={style.infoSection}>
          {slopeLength != null && (
            <ShortInfo description={"Length"} children={slopeLength.toFixed(2) + " km"} />
          )}
          {area.lift_count != null && (
            <ShortInfo description={"Lifts"} children={area.lift_count} />
          )}
          {area.restaurant_count != null && (
            <ShortInfo
              description={"Restaurants"}
              children={area.restaurant_count}
            />
          )}
          {area.ticket_price != null && (
            <ShortInfo
              description={"Ticket Price"}
              children={area.ticket_price + " €"}
            />
          )}
          {area.altitude_valley != null && (
            <ShortInfo
              description={"Valley"}
              children={area.altitude_valley.toFixed(0) + " m"}
            />
          )}
          {area.altitude_peak != null && (
            <ShortInfo
              description={"Peak"}
              children={area.altitude_peak.toFixed(0) + " m"}
            />
          )}
          {area.cable_car != null && (
            <ShortInfo
              description={"Amount Cable cars"}
              children={area.cable_car}
            />
          )}
          {area.gondola != null && (
            <ShortInfo
              description={"Amount gondola"}
              children={area.gondola}
            />
          )}
          {area.chair_lifts != null && (
            <ShortInfo
              description={"Amount chair lifts"}
              children={area.chair_lifts}
            />
          )}
        </div>
        { area.location && (
        <div>
          <ReactMap lat={area.location.split(",")[0]} long={area.location.split(",")[1]} />
        </div>
       )}
        {area.lifts != null ? (
          <AccordionLifts accordionHeading={"Lifts"} objectArray={area.lifts} />
        ) : (
          <AccordionDisabled accordionHeading={"Lifts"} />
        )}
        {area.slopes != null ? (
          <AccordionSlopes
            accordionHeading={"Slopes"}
            objectArray={area.slopes}
          />
        ) : (
          <AccordionDisabled accordionHeading={"Slopes"} />
        )}
      </div>
    </>
  );
};


function ShortenDescription(area) {
  var desc = ""
  desc += "The ski area "+ area.name+ " is located in "+(area.country? area.country : "") +". "
  desc += ""

  return desc.substring(0, 70);
}