export const daten = [
  [
    1,
    39782705,
    2069.4895865899944,
    "Gipfelbahn Hochwurzen",
    3.8908455653190934,
    "LINESTRING(13.6539977 47.376107,13.6532535 47.3753071,13.651222 47.3731234,13.6496004 47.3713802,13.6483888 47.3700778,13.6471613 47.3687582,13.6459988 47.3675085,13.6447818 47.3662001,13.6432816 47.3645873,13.6425609 47.3638125,13.6395309 47.3605547,13.6394738 47.3604933,13.6393606 47.3603716)",
  ],
  [
    2,
    91414482,
    19.663939540522968,
    "Ostabfahrt",
    3.364920890445134,
    "LINESTRING(13.6393606 47.3603716,13.6393209 47.3601968)",
  ],
  [
    3,
    91414482,
    162.93018464518593,
    "Ostabfahrt",
    1.4888015349690606,
    "LINESTRING(13.6393209 47.3601968,13.639401 47.3602634,13.6394405 47.3602847,13.6395438 47.3603318,13.6396389 47.3603574,13.639934 47.3603574,13.6402773 47.3603138,13.640626 47.3602665,13.6410337 47.3603065,13.641364 47.3603647)",
  ],
  [
    4,
    91414482,
    259.3425702094521,
    "Ostabfahrt",
    6.1033318073874305,
    "LINESTRING(13.641364 47.3603647,13.6415487 47.3603973,13.6417472 47.36051,13.6420529 47.3607643,13.6423104 47.3611459,13.6423533 47.3613639,13.6423051 47.3615383,13.6421817 47.3616255,13.641966 47.3616815,13.6417364 47.36172,13.6414341 47.3617618,13.6411 47.3618167)",
  ],
  [
    5,
    450680025,
    490.7638002979151,
    "Ostabfahrt",
    5.885023516395652,
    "LINESTRING(13.6411 47.3618167,13.6411649 47.3621926,13.6410247 47.3626805,13.6409115 47.363118,13.6404577 47.3640765,13.6400735 47.3647579,13.6398911 47.3652666,13.6395166 47.365842,13.6393374 47.3660071)",
  ],
  [
    6,
    450680024,
    772.6563373391036,
    "Mittelabfahrt",
    6.055764524251272,
    "LINESTRING(13.6393374 47.3660071,13.6388941 47.3663768,13.6384319 47.3667926,13.6380993 47.3670324,13.6380135 47.3672794,13.6380564 47.3676864,13.6381637 47.3682822,13.6382066 47.3688777,13.6381508 47.3691994,13.6381423 47.3692483,13.6383139 47.3696116,13.6386787 47.3698805,13.6389362 47.3702656,13.6388396 47.3706071,13.6386465 47.3709486,13.6383139 47.3714572,13.6381101 47.3720748,13.6378701 47.3723474)",
  ],
];
