import * as React from "react";
import Logo from "./assets/skinavie_logo.svg";
import styles from "./Header.module.css";
import { useNavigate } from "react-router-dom";
import { HelpModal } from "./HelpModal";
import {Icons} from "./Icon";
import {useEffect, useState} from "react";

export const Header = () => {
    const state = {visibility: "hidden"};
  const navigate = useNavigate();
  const [backVisibility, setBackVisibility] = useState(state);

  useEffect(() => {
      GetBack();
  }, [window.location.pathname]);

  function GetBack(){
      if(window.location.pathname !== "/"){
        setBackVisibility({visibility: "visible"});
      }
      else{
          setBackVisibility({visibility: "hidden"});
      }
  }
  return (
    <div className={styles.header}>
        <Icons iconName={"ArrowBack"} styling={backVisibility}
               onClick={() => {window.history.back()}} />
        {/*{window.location.pathname !== "/" && <Icons iconName={"ArrowBack"} styling={{visibility: "visible"}}*/}
        {/*onClick={() => {window.history.back()}} />}*/}
        {/*{window.location.pathname === "/" && <Icons iconName={"ArrowBack"} styling={{visibility: "hidden"}}*/}
        {/*                                            onClick={() => {window.history.back()}} />}*/}
      <img src={Logo} alt={"Logo SkiNaVie"} onClick={() => navigate("/")} />
        <HelpModal />
    </div>
  );
};
