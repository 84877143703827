import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import NavigationIcon from '@mui/icons-material/Navigation';
import ExploreIcon from '@mui/icons-material/Explore';
import MapIcon from '@mui/icons-material/Map';
import DirectionsIcon from '@mui/icons-material/Directions';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import {grey} from "@mui/material/colors";

export const Icons = ({iconName, onClick, styling}) => {
    return (
        <IconButton aria-label={iconName} onClick={onClick} sx={styling}>
            {iconName === "ArrowBack" && <ArrowBackIosNewIcon color={grey[500]}/>}
            {iconName === "NavigationIcon" && <NavigationIcon color={grey[500]}/>}
            {iconName === "ExploreIcon" && <ExploreIcon color={grey[500]}/>}
            {iconName === "MapIcon" && <MapIcon color={grey[500]}/>}
            {iconName === "DirectionsIcon" && <DirectionsIcon color={grey[500]}/>}
            {iconName === "MyLocationIcon" && <MyLocationIcon />}
        </IconButton>
    )
};
