import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DropDown, DropDownPreSelected } from "./Dropdown";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import style from "./GPS.module.css";
import { NavList } from "./NavList";
import { ReactMapWithSwiper } from "./MapPage";
import { ReactMap } from "./Map";
import { red } from "@mui/material/colors";

export const GPS = () => {

  let navReturn = [];

  const params = useParams();
  const [area, setArea] = useState({});
  const [start, setStart] = useState("");
  const [destination, setDestination] = useState("");
  const [fetchedRoute, setFetchedRoute] = useState(false);
  const url = `${process.env.REACT_APP_URL}/api/v1/areas/${params.Id}`;
  const [str, setStr] = useState([])

  useEffect(() => {
    fetch(url)
      .then((resp) => resp.json())
      //setDestination muss den namen des spots über die osm_id herausfinden
      .then((data) => {
        setArea(data);
      })
      .catch((error) => console.log(error));
  }, [url]);

    async function StartNavigation(){
        setFetchedRoute(false);
        if (start === null || start === ''){
            return null;
        }
        if (destination === null || destination === ''){
            return null;
        }

        const indexStart = allSpots.findIndex(element => { return element.name === start});
        const startObject = allSpots[indexStart];
        const indexDestination = allSpots.findIndex(element => { return element.name === destination});
        const destinationObject = allSpots[indexDestination];

        const urlStart = `https://overpass-api.de/api/interpreter?data=${encodeURIComponent(`[out:json];way(${startObject.osm_id});out geom;`)}`;
        const urlDestination = `https://overpass-api.de/api/interpreter?data=${encodeURIComponent(`[out:json];way(${destinationObject.osm_id});out geom;`)}`
        const navUrl = `${process.env.REACT_APP_URL}/api/v1/navigation/?
        ${encodeURIComponent(`
            x1=${startObject[1]}
            &x2=${startObject[0]}
            &y1=${destinationObject[1]}
            &y2=${destinationObject[0]}
          `)}
        `;


        const fetchObjectStart = await getapi(urlStart);

        const fetchObjectDestination = await getapi(urlDestination);

        navReturn = await getNav(navUrl)

        let linestring = []
        navReturn.map((elem) => {
          var arr = elem[5].replace("LINESTRING(", "").replace(")", "").split(",");
  
            for (let i = 0; i < arr.length; i++) {
              let ele = arr[i].split(" ");
              var x1 = parseFloat(ele[0]);
              var x2 = parseFloat(ele[1]);
              var obj = [x1, x2];
              linestring.push(obj);
            }
          });
        setStr(linestring)
        setFetchedRoute(true);
    }

  const allSpots = ConnectAllSpots(area.restaurants, area.lifts, area.slopes);
  return (
    <>
        <div className={style.dropdown}>
          <DropDown
            label={"Starting point"}
            value={start}
            values={allSpots}
            onChange={setStart}
            name={"starting"}
          ></DropDown>
          <DropDown
            label={"Destination point"}
            value={destination}
            values={allSpots}
            onChange={setDestination}
            name={"destination"}
          ></DropDown>
           <Button variant="contained" onClick={StartNavigation}>
            Start navigation
          </Button>
        </div>

      {fetchedRoute === true &&
        <>
          <ReactMapWithSwiper geodat={generateGeo(str)}></ReactMapWithSwiper>
          <NavList></NavList>
        </>
      }
    </>
  );
};

export function ConnectAllSpots(restaurants = [], lifts = [], slopes = []) {
  const elements = [];
    restaurants.forEach((restaurant) => {
      if (
        restaurant.name !== "" &&
        restaurant.name !== null &&
        restaurant.osm_id !== 0 &&
        restaurant.osm_id !== null
      ) {
        elements.push(restaurant);
      }
    });
  

    lifts.forEach((lift) => {
      if (
        lift.name !== "" &&
        lift.name !== null &&
        lift.name !== " " &&
        lift.osm_id !== 0 &&
        lift.osm_id !== null
      ) {
        elements.push(lift);
      }
    });
  
    slopes.forEach((slope) => {
      if (
        slope.name !== "" &&
        slope.name !== null &&
        slope.osm_id !== 0 &&
        slope.osm_id !== null
      ) {
        elements.push(slope);
      }
    });
  
  return elements;
}

function generateGeo(coords){

    return {
        "type": "FeatureCollection",
        "features": [{
            "type": "Feature",
            "geometry": {
                "type": "LineString",
                "coordinates": coords
            },
            "properties": {
                "prop0": "value0",
                "prop1": 0.0
            }
        }]
    }
}

async function getapi(url) {
  // Storing response
  const response = await fetch(url);

  // Storing data in form of JSON
  var data = await response.json();
  const latlong = [data.elements[0].bounds.minlon, data.elements[0].bounds.minlat];
  return latlong;
}

async function getNav(url) {
  // Storing response
  const response = await fetch(url);

  // Storing data in form of JSON
  var data = await response.json();
  return data;
}