import * as React from "react";
import ReactMapGL from "react-map-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it

/* eslint import/no-webpack-loader-syntax: off */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

//mapboxgl.accessToken =
//  "pk.eyJ1IjoibGl6emFidXJ1bm92YSIsImEiOiJjbDJxZzMyNWgxcWJ0M2JtdXZmM2d3MjNkIn0.JNfgF_LqWskUmYVTVmBKWw";

export const ReactMap = ({ lat, long }) => {
  // mapbox://lizzaburunova.3j3xscg7

  return (
    <>
      <ReactMapGL
        mapboxAccessToken="pk.eyJ1IjoibGl6emFidXJ1bm92YSIsImEiOiJja3oyczZsNGgwMHgxMnZtOHFqajdnZ3MyIn0.TdPj6J3ueE_evqR7tbM9FQ"
        initialViewState={{
          longitude: long,
          latitude: lat,
          zoom: 13,
        }}
        style={{ height: 400, marginTop: 50, marginBottom: 50 }}
        mapStyle="https://api.maptiler.com/maps/winter/style.json?key=SNTFwlDVCrzkTscvYOcn"
      ></ReactMapGL>
    </>
  );
};
