import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import style from "./Accordion.module.css";
import { grey, indigo, red} from "@mui/material/colors";

const DEFAULT_ARRAY = [];

export const AccordionDefault = ({accordionHeading, heading, children = DEFAULT_ARRAY}) => {
    return (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography variant="subtitle1">{accordionHeading}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="subtitle2" fontWeight={600}>
                        {heading}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" mt={2}>
                        {children}
                    </Typography>
                </AccordionDetails>
            </Accordion>
    );
};

export const AccordionLifts = ({accordionHeading, objectArray = DEFAULT_ARRAY}) => {
    function displayLiftType(liftType){
        switch(liftType){
            case "drag_lift":
                return "Drag Lift";
                break;
            case "chair_lift":
                return "Chair Lift";
                break;
        }
    }
    return(
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography variant="subtitle1">{accordionHeading}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                { objectArray.map((section) => {
                    if(section.name !== null && section.name != ""){
                    return(<div key={section.id}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                {section.name}
                            </Typography>
                        <div className={style.infobox}>
                            {section.seats !== null && <Typography variant="body2" color="text.secondary" mt={0} mb={3} align={"left"} width={'20%'}>
                                Seats: {section.seats}
                            </Typography>}
                            {section.lift_length !== null &&<Typography variant="body2" color="text.secondary" mt={0} mb={3} align={"right"} width={'30%'}>
                                {section.lift_length} m
                            </Typography>}
                            {section.lift_type !== null &&<Typography variant="body2" color="text.secondary" mt={0} mb={3} align={"right"} width={'30%'}>
                                {displayLiftType(section.lift_type)}
                            </Typography>}
                        </div>
                    </div>
                        )
                    }else{
                        return ""
                    }
                    }
                )}
            </AccordionDetails>
        </Accordion>
        )
}

export const AccordionSlopes = ({accordionHeading, objectArray = DEFAULT_ARRAY}) => {
    return(
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography variant="subtitle1">{accordionHeading}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                { objectArray.map((section) => {
                      if(section.name != null && section.name !== ""){
                        return(<div key={section.id}>
                                <Typography variant="subtitle2" fontWeight={600}>
                                    {section.name}
                                </Typography>
                                <div className={style.infobox}>
                                    {section.length != null &&<Typography variant="body2" color="text.secondary" mt={0} mb={3} width={'50%'} align={"right"}>
                                        {section.length} m
                                    </Typography>}
                                    {section.difficulty != null && GetColor(section.difficulty)}
                                </div>
                            </div>
                        )
                    }}
                )}
            </AccordionDetails>
        </Accordion>
    )
}

export const AccordionRestaurants = ({accordionHeading, objectArray = DEFAULT_ARRAY}) => {
    return(
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <Typography variant="subtitle1">{accordionHeading}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                { objectArray.map((section) => {
                        return(<div key={section.id}>
                                <Typography variant="subtitle2" fontWeight={600}>
                                    {section.name}
                                </Typography>
                            {section.address != null &&<Typography variant="body2" color="text.secondary" mt={0} align={"left"}>
                                    {section.address}
                                </Typography>}
                                {section.homepage != null &&<Typography variant="caption" color="text.secondary" mt={0} width={'50%'}>
                                    {section.homepage}
                                </Typography>}
                                <div className={style.infobox}>
                            {section.phone_number != null && <Typography variant="overline" color="text.secondary" mt={0} width={'50%'}>
                                    {section.phone_number}
                                </Typography>}
                            {section.rating != null && <Typography variant="overline" color="text.secondary" mt={0} width={'50%'} align={"right"}>
                                    Rating: {section.rating} / 5.0
                                </Typography>}</div>
                            </div>
                        )
                    }
                )}
            </AccordionDetails>
        </Accordion>
    )
}

export const AccordionDisabled = ({accordionHeading}) => {
    return(
        <Accordion disabled>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
            >
                <Typography variant="subtitle1">{accordionHeading}</Typography>
            </AccordionSummary>
        </Accordion>
    )
}

const GetColor = (text) => {
    const beginner = indigo[500];
    const moderate = red[500];
    const advanced = grey[900];

    if (text === "easy") {
        return (<Typography variant="overline" color={beginner} align={"right"} width={'90%'}>
            {text}
        </Typography>)
    }
    else if (text === "intermediate"){
        return(<Typography variant="overline" color={moderate} align={"right"} width={'90%'}>
            {text}
        </Typography>)
    }
    else if (text === "advanced") {
        return(<Typography variant="overline" color={advanced} align={"right"} width={'90%'}>
            {text}
        </Typography>)
    }
}
