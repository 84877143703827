import logo from "./logo.svg";
import "./App.css";
import * as React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Icons } from "./components/Icon";
import { AccordionCategorized, AccordionDefault } from "./components/Accordion";
import { CardDefault } from "./components/Card";
import { Navigation } from "./components/Navigation";
import { ShortInfo } from "./components/ShortInfo";
import { ExploreList } from "./components/ExploreListPage";
import { Typo } from "./components/Typographie";
import { Header } from "./components/Header";
import { ReactMapWithSwiper } from "./components/MapPage";
import { Area } from "./components/Area";
import { ImpressumLink } from "./components/ImpressumLink";
import "mapbox-gl/dist/mapbox-gl.css";

import { Impressum } from "./components/ImpressumText";
import {GPS} from "./components/GPS";
import * as PropTypes from "prop-types";


function App() {
 
  return (
    <Router>
      <div>
        <Header />
        <Navigation />
        <Routes>
          <Route path="/" element={<React.Suspense fallback={<div>Loading...</div>}><ExploreList /></React.Suspense>}></Route>
          <Route
            path="/area/:Id/map/:Id"
            element={
              <ReactMapWithSwiper
                lat={"47.431745"}
                long={"13.520587"}
              />
            }
          ></Route>
          <Route path="/impressum" element={<Impressum />}></Route>
          <Route path="/area/:Id" element={<React.Suspense fallback={<div>Loading...</div>}><Area /></React.Suspense>} />
          <Route path="/area/:Id/map/:Id/navigate/:Id" element={<React.Suspense fallback={<div>Loading...</div>}><GPS /></React.Suspense>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
