import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {OutlinedInput} from "@mui/material";

export const DropDown = ({value, values = [], onChange, name}) => {

    const handleChange = (evt) => {
        onChange(evt.target.value);
    };

    return (
        <div >
            <FormControl sx={{ m: 1, minWidth: 80, width:'90%' }}>
                <div htmlFor={"startInput"}>Select your {name} point</div>
                <Select
                    labelId="startInput"
                    id="startInput"
                    value={value}
                    onChange={handleChange}
                    label="start"
                >
                    {values.map((entry) => {
                            return (<MenuItem key={entry.osm_id} value={entry.name}>{entry.name}</MenuItem>)
                    })}
                </Select>
            </FormControl>
        </div>
    );
}

export const DropDownPreSelected = ({value, values = [], onChange}) => {
    const handleChange = (evt) => {
        onChange(evt.target.value);
    };

    return(
        <div>
            <FormControl sx={{ m: 1, minWidth: 80, width:'90%'}}>
                <div htmlFor={"destinationInput"}>Select your destination point</div>

                <Select
                    labelId="destinationInput"
                    id="destinationInput"
                    value={value}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={() => {
                            return value;
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                >

                    {values.map((entry) => {
                        if(value !== entry.name){
                            return (<MenuItem key={entry.osm_id} value={entry.name}>{entry.name}</MenuItem>)
                        }
                    })}
                </Select>
            </FormControl>
        </div>
    )
}
